import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import config from "@/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component:()=>import('../views/LayoutView.vue'),
    meta:{
      title:"主页面",
      requireAuth: true, // 判断是否需要登录
    },
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: 'home',
        component: ()=>import('../views/HomeView.vue'),
        meta:{
          title:"首页-我的工作台",
          menu_name:'首页',
          group:'home',
          group2: "home",
          sort:0,
          requireAuth: true, // 判断是否需要登录
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      title:"登录页"
    },
    component: () => import('../views/login/Index.vue')
  },
  {
    path: '/user_register',
    name: 'user_register',
    meta:{
      title:"注册"
    },
    component: () => import('../views/login/Register.vue')
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    meta:{
      title:"重置密码"
    },
    component: () => import('../views/login/resetPassword.vue')
  },
  {
    path: '/app',
    name: 'app',
    meta:{
      title:"晨光云APP"
    },
    component: () => import('../views/APPView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const authorityPages = window.localStorage.authorityPage as string;
if(authorityPages!=null && authorityPages!="") {
  // console.log(authorities);
  let authoritylist=[] as any;
  if (window.localStorage.authorities!=null &&  window.localStorage.authorities!="undefined") {
     authoritylist = JSON.parse(window.localStorage.authorities).split(',');
  }

  const pages = JSON.parse(authorityPages);
  pages.forEach((v: any) => {
    let pageItem = authoritylist.find((m: any) => m == v.authority) as any;
    if (pageItem != null) {
      const routerObj: RouteRecordRaw = {
        path: v.path,
        name: v.name,
        component: () => import(/* webpackChunkName: '[request]'*/`../views/${v.pathPage.replace("../views/", "")}`),
        // redirect: v.redirect,
        meta: {
          title: v.title,
          menu_name: v.menuName,
          group: v.group,
          group2: v.group2,
          sort: v.sort,
          requireAuth: v.requireAuth, // 判断是否需要登录
        }
      }
      if (v.group == "home") {
        router.addRoute('main', routerObj);
      } else if (v.group == "setting") {
        if (router.hasRoute("setting")) {
          router.addRoute('setting', routerObj);
        }
      }
    }
  });
}


//路由拦截
router.beforeEach((to,from,next)=> {
  if (to.meta.title) {
    document.title = to.meta.title +" - "+ config.APP_NAME
  }else {
    next({ path: '/login', query: {redirect: to.fullPath}})
  }
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (window.sessionStorage.token) { // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({ path: '/login', query: {redirect: to.fullPath}})
    }
  } else {
    next();
  }
})


export default router
